import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import '../style.scss';
const IconActionTableEdit = (props) => {
  const { handleActionEdit } = props;
  //<IconButton aria-label="delete" onClick={() => handleActionEdit(row)}>
  return (
    <Tooltip title="Editar" arrow className="buttonIconAction">
      <IconButton 
        classes={{
          label: "buttonContainer buttonPrimary",
          root: 'buttonRoot'
        }}
        aria-label="edit"
        onClick={handleActionEdit}>
        <EditIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}

export default IconActionTableEdit;