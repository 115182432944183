import React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { IconButton, Tooltip } from '@material-ui/core';
import UserStatus from '../../../common/userStatus.json';

const IconActionTableStateOn = (props) => {
	const { handleActionState, rowState } = props;

	return (
		<Tooltip title={props.tooltip ? props.tooltip : 'Cambio de estado'}>
			<IconButton
				classes={{
					label: 'buttonContainer buttonPrimary',
					root: 'buttonRoot',
				}}
				aria-label="state"
				onClick={handleActionState}
			>
				{(rowState === UserStatus.UserStatus[0].active && (
					<CheckBoxIcon fontSize="small" />
				)) ||
					(rowState === UserStatus.UserStatus[1].active && (
						<CheckBoxOutlineBlankIcon fontSize="small" />
					))}
			</IconButton>
		</Tooltip>
	);
};

export default IconActionTableStateOn;
