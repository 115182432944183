import React, { useState, useEffect } from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import SyncIcon from '@material-ui/icons/Sync';
import SnackBarsComponent from '../SnackBarsComponent';
import CircularProgress from '@material-ui/core/CircularProgress';

import { IsDesktopHandler } from '../../helpers';
import dialogMessages from '../../common/dialogMessages.json';
import classes from './DialogState.module.scss';

const DialogState = (props) => {
	const {
		openDialog,
		rowState,
		actionState,
		success,
		loading,
		openState,
	} = props;
	const [valueState, setValueState] = useState(false);
	const [disableState, setDisableState] = useState(loading);
	const [successState, setSuccessState] = useState(false);

	const isDesktop = IsDesktopHandler('md');
	useEffect(() => {
		if (openDialog) {
			setValueState(true);
			setDisableState(false);
		}
	}, [openDialog]);

	useEffect(() => {
		setDisableState(loading);
	}, [loading]);

	useEffect(() => {
		if (success !== undefined) {
			setSuccessState(true);
		}
	}, [success]);

	const handleClick = (event, reason) => {
		if (reason == 'backdropClick') {
			return false;
		} else {
			setValueState(false);
			openState(false);
		}
	};

	const handleClickConfirm = async () => {
		await actionState(rowState);
		openState(false);
		setValueState(false); //cierra el dialo
	};

	const handleCloseSnackBarsComponent = () => {
		setSuccessState(false);
	};

	return (
		<React.Fragment>
			<Dialog
				fullScreen={!isDesktop}
				open={valueState}
				classes={{
					paper: classes.paperStyle,
				}}
				onClose={handleClick}
				// disableBackdropClick
				disableEscapeKeyDown
				aria-labelledby="dialog-title"
			>
				<DialogTitle id="dialog-title" className={classes.title}>
					{dialogMessages.message.state.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText
						component="div"
						className={classes.description}
					>
						<p className="mb-0">
							{dialogMessages.message.state.subtitle}
						</p>
						<p style={{ fontWeight: 'bold' }}>
							{rowState.id ? `ID ${rowState.id} - ` : null}
							{rowState.name ? rowState.name : null}{' '}
							{rowState.quarter ? `Cuartel: ${rowState.quarter} ` : null}{' '}
							{rowState.names ? rowState.names : null}{' '}
							{rowState.lastname ? rowState.lastname : null}
							{rowState.lastNames ? rowState.lastNames : null}
						</p>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						autoFocus
						onClick={handleClick}
						disabled={disableState}
					>
						Cancelar
					</Button>
					<div className={classes.wrapper}>
						<Button
							onClick={handleClickConfirm}
							variant="contained"
							color="primary"
							autoFocus
							disabled={disableState}
							startIcon={<SyncIcon />}
						>
							Cambiar
						</Button>
						{disableState && (
							<CircularProgress
								style={{ color: 'white' }}
								size={24}
								className={classes.buttonProgress}
							/>
						)}
					</div>
				</DialogActions>
			</Dialog>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={successState}
				autoHideDuration={6000}
			>
				<SnackBarsComponent
					variant={props.variant}
					message={dialogMessages.message.state.confirmation}
					handleClose={handleCloseSnackBarsComponent}
				/>
			</Snackbar>
		</React.Fragment>
	);
};

export default DialogState;
